import React from 'react';
import { NavLink, useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../services/firebase';
import { FaHome, FaTrophy, FaVoteYea, FaUserFriends, FaListAlt, FaSignOutAlt, FaCrown, FaUser, FaComments } from 'react-icons/fa';
import '../../styles/Layout.css';
import Chat from '../../services/Chat';

const Layout = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <div className="layout">
      <header className="app-header">
        <div className="header-content">
          <h1 style={{ textAlign: 'center', width: '100%' }}><FaCrown style={{ marginRight: '10px' }} />Miss France</h1>
          {currentUser && (
            <button className="logout-button" onClick={handleLogout}>
              <FaSignOutAlt />
            </button>
          )}
        </div>
      </header>
      <nav className="app-nav">
        <NavLink to="/" end><FaHome /> <span>Home</span></NavLink>
        <NavLink to="/scoreboard"><FaTrophy /> <span>Scoreboard</span></NavLink>
        <NavLink to="/submit-bets"><FaVoteYea /> <span>Submit Bets</span></NavLink>
        <NavLink to="/candidates"><FaUserFriends /> <span>Candidates</span></NavLink>
        <NavLink to="/my-bets"><FaListAlt /> <span>My Bets</span></NavLink>
        <NavLink to="/profile"><FaUser /> <span>Profile</span></NavLink>
      </nav>
      <main className="main-content">
        <Outlet />
      </main>
      <Chat />
    </div>
  );
};

export default Layout;
