import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import './styles/global.css';

const AppWrapper = () => {
  return (
    <div className="app-wrapper">
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </div>
  );
};

export default AppWrapper;
