import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, firestore } from './firebase';
import { useAuth } from '../contexts/AuthContext';
import { FaGoogle } from 'react-icons/fa';
import { FaCrown } from 'react-icons/fa';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import '../styles/Login.css';

const Login = () => {
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const { currentUser } = useAuth();

	useEffect(() => {
		if (currentUser) {
			console.log('User is already logged in, redirecting to home');
			navigate('/');
		}
	}, [currentUser, navigate]);

	const handleGoogleLogin = async () => {
		const provider = new GoogleAuthProvider();
		try {
			const result = await signInWithPopup(auth, provider);
			console.log('Google sign-in successful', result.user);
			await createUserDocument(result.user);
			navigate('/');
		} catch (error) {
			setError('Failed to log in with Google: ' + error.message);
			console.error('Google sign-in error:', error);
		}
	};

	const createUserDocument = async (user) => {
		const userDocRef = doc(firestore, 'users', user.uid);
		const userDoc = await getDoc(userDocRef);
		if (!userDoc.exists()) {
			const userData = {
				id: user.uid,
				name: user.displayName,
				email: user.email,
				role: 'user', // Default role, you can customize this
				createdAt: new Date()
			};
			await setDoc(userDocRef, userData);
			console.log('User document created:', userData);
		} else {
			console.log('User document already exists:', userDoc.data());
		}
	};

	return (
		<div className="login-container">
			<div className="login-box">
				<h1><FaCrown style={{ marginRight: '5px' }} />Miss France 2025</h1>
				<p>Sign in to start betting</p>
				{error && <p className="error-message">{error}</p>}
				<button className="google-login-button" onClick={handleGoogleLogin}>
					<FaGoogle /> Sign in with Google
				</button>
			</div>
		</div>
	);
};

export default Login;
