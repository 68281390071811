import React from 'react';
import Chat from '../../services/Chat';

const ChatPage = () => {
    return (
        <div className="chat-page-container">
            <h2><center>Chat Room</center></h2>
            <Chat />
        </div>
    );
};

export default ChatPage;
