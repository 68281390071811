import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/common/Layout';
import Login from './services/Login';
import Home from './components/pages/Home';
import Scoreboard from './components/Scoreboard';
import SubmitBets from './components/SubmitBets';
import Candidates from './components/pages/Candidates';
import MyBets from './components/pages/MyBets';
import Profile from './components/pages/Profile';
import ChatPage from './components/pages/ChatPage';

function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="scoreboard" element={<Scoreboard />} />
            <Route path="submit-bets" element={<SubmitBets />} />
            <Route path="candidates" element={<Candidates />} />
            <Route path="my-bets" element={<MyBets />} />
            <Route path="profile" element={<Profile />} /> {/* Add the Profile route */}
            <Route path="chat" element={<ChatPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;

