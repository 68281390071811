import React from 'react';
import '../../styles/Home.css';
import Chat from '../../services/Chat.js';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Miss France Betting App</h1>
      <Chat />
      <p>Bienvenue sur l'application de paris pour Miss France 2025 ! Parie sur ta candidate favorite et suis le classement en direct.</p>
      <Chat />
    </div>
  );
};

export default Home;
