// components/Scoreboard.js
import React, { useState, useEffect, useCallback } from 'react';
import { firestore } from '../services/firebase.js';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { calculatePoints } from '../utils/scoring.js';
import '../styles/Scoreboard.css';


const Scoreboard = () => {
    const [users, setUsers] = useState([]);
    const [results, setResults] = useState(null);
    const [userPoints, setUserPoints] = useState({});

    const fetchUsers = useCallback(async () => {
        try {
            const usersCollection = collection(firestore, 'users');
            const usersSnapshot = await getDocs(usersCollection);
            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUsers(usersList);
            console.log('Users fetched:', usersList);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, []);

    const fetchResults = useCallback(async () => {
        try {
            const resultsDoc = doc(firestore, 'results', 'current');
            const resultsSnapshot = await getDoc(resultsDoc);
            if (resultsSnapshot.exists()) {
                setResults(resultsSnapshot.data());
                console.log('Results fetched:', resultsSnapshot.data());
            } else {
                console.log('Results document does not exist.');
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    }, []);

    const fetchUserBets = async (userId) => {
        try {
            const betsCollection = collection(firestore, 'bets');
            const userBetsQuery = query(betsCollection, where('userId', '==', userId));
            const userBetsSnapshot = await getDocs(userBetsQuery);
            return userBetsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error('Error fetching user bets:', error);
            return [];
        }
    };

    const fetchAllUserBets = useCallback(async () => {
        const pointsMap = {};
        for (const user of users) {
            const userBets = await fetchUserBets(user.id);
            const { totalPoints } = calculatePoints(userBets, results);
            pointsMap[user.id] = totalPoints;
        }
        setUserPoints(pointsMap);
        console.log('User points calculated:', pointsMap);
    }, [users, results]);

    useEffect(() => {
        fetchUsers();
        fetchResults();
    }, [fetchUsers, fetchResults]);

    useEffect(() => {
        if (results && users.length > 0) {
            fetchAllUserBets();
        }
    }, [results, users, fetchAllUserBets]);

    if (!results || users.length === 0) {
        console.log('Loading state:', { results, users });
        return <div>Loading...</div>;
    }

    console.log('Rendering Scoreboard with users:', users, 'and userPoints:', userPoints);

    return (
        <div className="scoreboard-container">
            <h2>Scoreboard</h2>
            <div className="scoreboard-list">
                {users.map(user => (
                    <div key={user.id} className="scoreboard-item">
                        <h3>{user.displayName || user.email}</h3>
                        <p>Total Points: {userPoints[user.id] || 0}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Scoreboard;