import React, { useState, useEffect } from 'react';
import { firestore } from '../services/firebase';
import { collection, getDocs, addDoc, query, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import '../styles/SubmitBets.css';

const SubmitBets = () => {
    const [candidates, setCandidates] = useState([]);
    const [userBets, setUserBets] = useState({});
    const [selectedBets, setSelectedBets] = useState({
        top15: [],
        top5: [],
        winner: ''
    });
    const [results, setResults] = useState({ top15: [], top5: [] });
    const [selectedCategory, setSelectedCategory] = useState('top15');
    const { currentUser } = useAuth();
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await fetchCandidates();
            await fetchResults();
            await fetchUserBets();
            setIsLoading(false);
        };

        fetchData();
    }, [currentUser]);

    const fetchCandidates = async () => {
        try {
            const candidatesCollection = collection(firestore, 'candidates');
            const candidatesSnapshot = await getDocs(candidatesCollection);
            const candidatesList = candidatesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCandidates(candidatesList);
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    };

    const fetchResults = async () => {
        try {
            const resultsDoc = doc(firestore, 'results', 'current');
            const resultsSnapshot = await getDoc(resultsDoc);
            if (resultsSnapshot.exists()) {
                setResults(resultsSnapshot.data());
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };

    const fetchUserBets = async () => {
        if (!currentUser) return;

        try {
            const betsCollection = collection(firestore, 'bets');
            const userBetsQuery = query(betsCollection, where('userId', '==', currentUser.uid));
            const userBetsSnapshot = await getDocs(userBetsQuery);
            const userBetsData = {};
            userBetsSnapshot.docs.forEach(doc => {
                const bet = doc.data();
                userBetsData[bet.category] = bet.candidateIds || bet.candidateId;
            });
            setUserBets(userBetsData);
            setSelectedBets(userBetsData);
        } catch (error) {
            console.error('Error fetching user bets:', error);
        }
    };

    const handleBetChange = (category, candidateId) => {
        setSelectedBets(prev => {
            if (category === 'winner') {
                return { ...prev, [category]: candidateId };
            } else {
                const updatedBets = prev[category] && prev[category].includes(candidateId)
                    ? prev[category].filter(id => id !== candidateId)
                    : [...(prev[category] || []), candidateId];

                // Prevent selecting more than the allowed number of candidates
                if ((category === 'top15' && updatedBets.length > 15) || (category === 'top5' && updatedBets.length > 5)) {
                    return prev;
                }

                return { ...prev, [category]: updatedBets };
            }
        });
    };

    const submitBet = async (category) => {
        if (!currentUser) return;

        const selectedCandidates = selectedBets[category];
        if (
            (category === 'top15' && selectedCandidates.length !== 15) ||
            (category === 'top5' && selectedCandidates.length !== 5) ||
            (category === 'winner' && !selectedCandidates)
        ) {
            alert(`Please select ${category === 'top15' ? '15' : category === 'top5' ? '5' : '1'} candidate(s) for ${category}`);
            return;
        }

        try {
            const betsCollection = collection(firestore, 'bets');
            await addDoc(betsCollection, {
                userId: currentUser.uid,
                category,
                candidateIds: category === 'winner' ? [selectedCandidates] : selectedCandidates,
                timestamp: new Date()
            });
            setUserBets(prev => ({ ...prev, [category]: selectedCandidates }));
            alert(`Bet for ${category} submitted successfully!`);
        } catch (error) {
            console.error('Error submitting bet:', error);
            alert('Failed to submit bet. Please try again.');
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const renderBetCategory = (category, label, count) => {
        const isDisabled = userBets[category] !== undefined;
        if (isDisabled) return null; // Hide the category if already submitted

        const selected = selectedBets[category] || [];
        const isSubmitDisabled = category !== 'winner' ? selected.length !== count : !selected;

        // Filter candidates based on the selected category and search query
        const validCandidates = category === 'top15' ? candidates :
                                category === 'top5' ? candidates.filter(c => results.top15.includes(c.id)) :
                                candidates.filter(c => results.top5.includes(c.id));

        const filteredCandidates = validCandidates.filter(candidate => {
            const regionMatch = typeof candidate.region === 'string' && 
                candidate.region.toLowerCase().includes(searchQuery.toLowerCase());
            const nameMatch = typeof candidate.name === 'string' && 
                candidate.name.toLowerCase().includes(searchQuery.toLowerCase());
            return regionMatch || nameMatch;
        });

        return (
            <div className="bet-category">
                <h3>{label}</h3>
                <div className="candidates-grid">
                    {filteredCandidates.map(candidate => {
                        const isSelected = category === 'winner' ? selected === candidate.id : selected.includes(candidate.id);
                        return (
                            <div 
                                key={candidate.id} 
                                className={`candidate-item ${isSelected ? 'selected' : ''}`} 
                                onClick={() => handleBetChange(category, candidate.id)}
                            >
                                <div className="candidate-image-container">
                                    <img 
                                        src={candidate.imageUrl} 
                                        alt={candidate.name} 
                                        className="candidate-image" 
                                    />
                                </div>
                                <div className="candidate-info">
                                    <span className="candidate-region">{candidate.region}</span>
                                    <span className="candidate-name">{candidate.name}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button 
                    onClick={() => submitBet(category)} 
                    disabled={isSubmitDisabled}
                    className={isSubmitDisabled ? 'submit-button disabled' : 'submit-button'}
                >
                    Submit
                </button>
            </div>
        );
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="submit-bets-container">
            <div className="submit-bets-content">
                <h2>Submit Your Bets</h2>
                <div className="bet-selection-label">Select your bet:</div>
                <div className="bet-selection">
                    <button 
                        className={`bet-button ${selectedCategory === 'top15' ? 'active' : ''}`} 
                        onClick={() => setSelectedCategory('top15')}
                        disabled={userBets.top15 !== undefined}
                    >
                        Top 15
                    </button>
                    <button 
                        className={`bet-button ${selectedCategory === 'top5' ? 'active' : ''}`} 
                        onClick={() => setSelectedCategory('top5')}
                        disabled={userBets.top5 !== undefined}
                    >
                        Top 5
                    </button>
                    <button 
                        className={`bet-button ${selectedCategory === 'winner' ? 'active' : ''}`} 
                        onClick={() => setSelectedCategory('winner')}
                        disabled={userBets.winner !== undefined}
                    >
                        Winner
                    </button>
                </div>
                <div className="search">
                    <input 
                        type="text" 
                        placeholder="Search by region or name..." 
                        value={searchQuery} 
                        onChange={handleSearchChange} 
                        className="search-input"
                    />
                </div>
                {selectedCategory === 'top15' && renderBetCategory('top15', 'Top 15', 15)}
                {selectedCategory === 'top5' && renderBetCategory('top5', 'Top 5', 5)}
                {selectedCategory === 'winner' && renderBetCategory('winner', 'Winner', 1)}
            </div>
        </div>
    );
};

export default SubmitBets;