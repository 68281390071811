import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../services/firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import '../../styles/Candidates.css';

const Candidates = () => {
    const [candidates, setCandidates] = useState([]);
    const [filter, setFilter] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCandidates();
        fetchResults();
    }, []);

    const fetchCandidates = async () => {
        try {
            const candidatesCollection = collection(firestore, 'candidates');
            const candidatesSnapshot = await getDocs(candidatesCollection);
            const candidatesList = candidatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCandidates(candidatesList);
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    };

    const fetchResults = async () => {
        try {
            const resultsDoc = doc(firestore, 'results', 'current');
            const resultsSnapshot = await getDoc(resultsDoc);
            if (resultsSnapshot.exists()) {
                setResults(resultsSnapshot.data());
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };

    const handleFilterChange = (filterValue) => {
        setFilter(filterValue);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredCandidates = candidates.filter(candidate => {
        const matchesSearch = candidate.region.toLowerCase().includes(searchQuery.toLowerCase());
        if (filter === 'All') {
            return matchesSearch;
        } else if (filter === 'Top 15' && results?.top15.includes(candidate.id)) {
            return matchesSearch;
        } else if (filter === 'Top 5' && results?.top5.includes(candidate.id)) {
            return matchesSearch;
        } else if (filter === 'Winner' && results?.winner === candidate.id) {
            return matchesSearch;
        }
        return false;
    });

    return (
        <div className="candidates">
            <h2>Candidates</h2>
            <div className="filter">
                <button 
                    className={`filter-button ${filter === 'All' ? 'active' : ''}`} 
                    onClick={() => handleFilterChange('All')}
                >
                    All
                </button>
                <button 
                    className={`filter-button ${filter === 'Top 15' ? 'active' : ''}`} 
                    onClick={() => handleFilterChange('Top 15')}
                >
                    Top 15
                </button>
                <button 
                    className={`filter-button ${filter === 'Top 5' ? 'active' : ''}`} 
                    onClick={() => handleFilterChange('Top 5')}
                >
                    Top 5
                </button>
                <button 
                    className={`filter-button ${filter === 'Winner' ? 'active' : ''}`} 
                    onClick={() => handleFilterChange('Winner')}
                >
                    Winner
                </button>
            </div>
            <div className="search">
                <input 
                    type="text" 
                    placeholder="Search by region..." 
                    value={searchQuery} 
                    onChange={handleSearchChange} 
                    className="search-input"
                />
            </div>
            <div className="candidates-list">
                {filteredCandidates.map(candidate => (
                    <div 
                        key={candidate.id} 
                        className="candidate-item"
                    >
                        <div className="candidate-image-container">
                            <img 
                                src={candidate.imageUrl} 
                                alt={candidate.name} 
                                className="candidate-image" 
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                        <div className="candidate-info">
                            <h3>{candidate.region}</h3>
                            <p><b>{candidate.name}</b></p>
                            <p><b>Age:</b> {candidate.age}</p>
                            <p><b>Height:</b> {candidate.height}</p>
                            <p><b>Ranking:</b> {candidate.final_ranking}</p>
                            <p>{candidate.result}</p>
                            <a href={candidate.reference} target="_blank" rel="noopener noreferrer">More Info</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Candidates;
