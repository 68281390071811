export const calculatePoints = (userBets, results) => {
    let totalPoints = 0;
    const pointsPerBet = {};

    for (const bet of userBets) {
        let points = 0;
        if (bet.category === 'winner') {
            points = bet.candidateIds.includes(results.winner) ? 5 : 0;
        } else {
            points = bet.candidateIds.reduce((acc, candidateId) => {
                return acc + (results[bet.category].includes(candidateId) ? 1 : 0);
            }, 0);
        }
        pointsPerBet[bet.category] = points;
        totalPoints += points;
    }

    return { totalPoints, pointsPerBet };
};